<template>
  <div class="wrapper">
    <tree-to-vliew :isDeep="false" @request="request"></tree-to-vliew>
    <right-content>
      <div class="right-content_head">
        <span class="title">{{ labelName }}:监督岗 {{ total }} 个</span>
        <el-button @click="exportSentry">导出成员</el-button>
      </div>
      <div class="right-content_table">
        <div class="table">
          <my-table
            @currentPage="currentPage"
            :tableInfo="tableInfo"
            :colConfigs="colConfigs"
          >
            <el-table-column
              width="260px"
              align="center"
              slot="option"
              label="操作"
            >
              <template slot-scope="{ row }">
                <div class="option">
                  <el-button
                    icon="iconfont icon-chakan-copy1"
                    @click="check(row)"
                  >查看
                  </el-button
                  >
                </div>
              </template>
            </el-table-column>
          </my-table>
        </div>
      </div>
    </right-content>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable'
import TreeToVliew from '../../commonViews/TreeToVliew'
import { exportUser, getSentryList } from '@/api'
import RightContent from '../../commonViews/RightContent'
import { exportFun } from '@/utils/params'

export default {
  name: 'supervision',
  components: {
    RightContent,
    TreeToVliew,
    MyTable
  },
  watch: {
    page (curent) {
      this.request({
        ...this.requestParam,
        page: curent,
      })
    },
  },
  methods: {
    exportSentry () {
      const param = {
        type: 2,
        department_id: this.department_id || '',
        company_id: localStorage.getItem('company_id'),
      }
      exportFun(exportUser, param, 'exportUser')
    },
    //分页
    currentPage (current) {
      this.page = current
    },
    async getSentrysData (data) {
      const res = await getSentryList(data)
      if (res.status === 200) {
        this.tableInfo = res.data.data
        this.total = res.data.data.total
      }
    },
    request (request) {
      let {
        label,
        ...params
      } = request
      this.labelName = label
      this.requestParam = params
      this.department_id = request.department_id
      this.getSentrysData(params)
    },
    check ({
      id,
      name
    }) {
      this.$router.push({
        name: 'managerList-detail',
        query: {
          id,
          name
        },
      })
    },
  },
  data () {
    return {
      labelName: '',
      tableInfo: {},
      department_id: 0,
      page: 1,
      total: 0,
      paginConfigs: {},
      colConfigs: [
        {
          prop: 'id',
          label: '序号',
          width: '80px'
        },
        {
          prop: 'name',
          label: '监督岗名称'
        },
        {
          prop: 'sentries_number',
          label: '参与人数'
        },
        { slot: 'option' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;

  .right-content_head {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    span {
      margin-bottom: 20px;
    }

    button {
      background-color: red;
      color: #fff;
      width: 150px;
      padding: 10px 25px;
    }
  }

  .right-content_table {
    .table {
      margin: 20px 0;
    }

    button {
      padding: 7px 15px;
      background: #fde5e4;
      border-radius: 4px;
      border: 0;
      font-size: 15px;
      font-family: PingFang SC;
      color: #e32322;
    }
  }
}
</style>
